.header{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.header__link{
    color: black;
    text-decoration: none;
}

.header__links{
    display: grid;
    grid-auto-flow: column;
    gap: 10px;
}