.resultCard{
    padding: 10px 5%;

    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 2fr;
    // grid-template-rows: 1fr 1fr;
    grid-template-areas: 'i coords' 'i event' 'i attach';
}

.resultCard__attachments{
    grid-area: attach;
}

.resultCard__image{
    grid-area: i;
    width: 100%;
    height: auto;
    aspect-ratio: 1;
    object-fit: contain;
    background-color: #c4c4c4;
}

.resultCard__id{
    grid-area: id;
    margin: 0;
    font-size: 0.9em;
}

.resultCard__coords{
    grid-area: coords;
    margin: 0;
}

.resultCard__event{
    grid-area: event;
}

.resultCard__eventButton{
    &:not([disabled])[data-active]{
        background-color: #e1e7ff;
    }
}