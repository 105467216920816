.sheet{
    --rsbs-backdrop-bg: rgba(0, 0, 0, 0.6);
    --rsbs-bg: #e4e4e4;
    --rsbs-handle-bg: hsla(0, 0%, 0%, 0.14);
    --rsbs-max-w: auto;
    --rsbs-ml: env(safe-area-inset-left);
    --rsbs-mr: env(safe-area-inset-right);
    --rsbs-overlay-rounded: 16px;

    [data-rsbs-overlay]{
        max-width: min(var(--max-app-with), 100vw);
        margin: auto;
    }

    z-index: 100;
}

.footer{
    display: grid;
    gap: 1em;
}

.placesWrap{
    max-width: 100%;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: hsl(0 0% 50%);
    &::-webkit-scrollbar {
        display: none;
    }
}

.places{
    display: grid;
    grid-auto-flow: column;
    gap: 0.5em;
    width: max-content;
    margin: 0 0 0.5em;
}

.actions{
    display: grid;
    gap: 10px;
    grid-auto-flow: column;
}

.geoPopupPane{
    padding: 16px;
}

.mapCenter{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 400;
    pointer-events: none;
}

.addNewPlace{
    display: grid;
    padding: 1em;

    background: #f6f6f6;
    border-radius: 1em;
    gap: 1em;
}