.resultSheet{
    --rsbs-backdrop-bg: rgba(0, 0, 0, 0.6);
    --rsbs-bg: #e4e4e4;
    --rsbs-handle-bg: hsla(0, 0%, 0%, 0.14);
    --rsbs-max-w: auto;
    --rsbs-ml: env(safe-area-inset-left);
    --rsbs-mr: env(safe-area-inset-right);
    --rsbs-overlay-rounded: 16px;

    [data-rsbs-overlay]{
        max-width: min(var(--max-app-with), 100vw);
        margin: auto;
    }
}

.resultActions{
    display: grid;
    gap: 10px;
    grid-auto-flow: column;
}

.resultActionsCard{
    padding: 10px 5%;
    display: grid;
    gap: 10px;
}