.App {
  display: grid;
  grid-template-rows: max-content 1fr;
  gap: 20px;

  max-width: calc(min(40px + calc(var(--max-app-with)), 100vw));
  margin: auto;

  padding: 20px;

  background-color: rgb(242, 242, 242);

  min-height: var(--app-height);
  box-sizing: border-box;
}

.appMain{
  display: grid;
}