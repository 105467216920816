.newDocCard{
    background: rgb(242, 242, 242);
    padding: 10px;

    display: grid;
}

.photoCard{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    height: max-content;

    height: 100%;
    width: 100%;
    aspect-ratio: 1;

    border-radius: 4px;
    overflow: hidden;
    background-color: #b7b7b7;
    white-space: wrap;
    line-height: 1;
}