.attachmentsBody{
    display: grid;
    gap: 20px;

    padding: 10px;
}

.attachmentsList{
    display: grid;
    gap: 10px;
}

.attachmentCard{
    background: rgb(242, 242, 242);
    padding: 10px;
    align-items: center;

    display: grid;
    grid-template-columns: 2fr 20fr 1fr;
    gap: 10px;
}

.closeButton{
    position: fixed;
    top: 10px;
    right: 10px;
}