.scanner{
    display: grid;
    height: 100%;
    gap: 40px;
    align-content: space-between;
}

.video{
    // border: 1px solid red;
    width: calc(min(var(--max-app-with), 90vw));
    height: calc(min(var(--max-app-with), 90vw));

    background: #b7b7b7;
    border-radius: 20px;
}

.buttons{
    display: grid;
    gap: 10px;
    grid-auto-rows: max-content;
}