.eventList{
    display: grid;
    gap: 10px;
}

.event{
    background-color: rgb(230 230 230);
    border-radius: 10px;

    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-areas: "d i" "p ic";
    align-items: center;
    gap: 10px 10px;

    padding: 10px;

    &.event_white{
        background-color: white;
        padding: 0;
    }

    &.event_popup{
        grid-template-columns: 1fr;
        grid-template-areas: "d" "p" "t" "a" "i";
        grid-auto-flow: column;
        gap: 10px;
    }

    p{
        margin: 0;
    }
}

.event__actor{
    // grid-area: a;

    p{
        margin: 0;
    }
}

.event__date{
    margin: 0;
    grid-area: d;
}

.event__placeName{
    margin: 0;
    grid-area: p;
}

.event__image{
    grid-area: i;
    font-size: 0.8em;
}

.event__type{
    // grid-area: t;
}

.event__icons{
    grid-area: ic;

    display: grid;
    grid-auto-flow: column;
    gap: 10px;
    align-items: center;
    justify-content: start;
}

.event__value{
    display: inline-grid;
    grid-auto-flow: column;
    gap: 0.5em;
    align-items: center;
    justify-content: start;
    
    padding: 0.3em;
    background-color: rgb(215, 215, 215);
    border-radius: 0.3em;
}

.map{
    height: 20vw;
    z-index: 0;

    &.map_expanded{
        height: min(var(--max-app-with) * 0.8, 80vw);
    }
}

.mapIconMarker{
    background: transparent;

    border-radius: 50%;
    overflow: hidden;
    border: 1px #fff solid;
    outline: 1px rgb(215, 215, 215) solid;
}

.mapIcon{
    width: inherit;
    height: inherit;
}

.pagination{
    position: sticky;
    bottom: 1em;

    display: grid;
    grid-auto-flow: column;
    align-items: baseline;
    justify-content: space-around;

    background: white;
    border-radius: 1em;
}